<template>
  <div>
    <filter-slot
      :filter="filters"
      :filter-principal="filterPrincipal"
      :total-rows="total_data"
      :paginate="paginate"
      :start-page="start_page"
      :to-page="to_page"
      :send-multiple-sms="false"
      @reload="$refs.refTickets.refresh()"
      @onSelectChange="changeModule"
      class="filter-slot-new-customization"
      show-minimal
      input-width="60"
    >
      <template #table>
        <b-table
          ref="refTickets"
          class="blue-scrollbar position-relative table-new-customization"
          :items="myProvider"
          :fields="headersTable"
          primary-key="id"
          responsive="sm"
          show-empty
          small
          sticky-header="65vh"
          table-class="detail-hd"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :filter="search_input"
          :tbody-tr-class="rowClass"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(ticket)="data">
            <div
              :class="ticketClasses"
              class="clickable"
              @click="viewTicket(data.item)"
            >
              {{ data.item.title }}
              <br />
              {{ data.item.ticket_id }}
            </div>
          </template>

          <template #cell(binnacle)="data">
            <feather-icon
              icon="BookOpenIcon"
              size="18"
              class="clickable"
              :class="
                data.item.status_view_messages == 0 &&
                data.item.sender_message_id != currentUser.user_id
                  ? 'text-warning flashes'
                  : 'text-secondary text-muted'
              "
              @click="openDescription(data.item)"
            />
          </template>

          <template #cell(user)="data">
            <div>{{ data.item.user }}</div>
            <div>{{ data.item.created_at | myGlobalDay }}</div>
          </template>

          <template #cell(category_ticket)="data">
            <b-badge
              v-if="data.item.category_ticket == 'support'"
              variant="info"
              class="text-uppercase"
              >{{ data.item.category_ticket }}</b-badge
            >
            <b-badge variant="warning" class="text-uppercase" v-else>{{
              data.item.category_ticket
            }}</b-badge>
          </template>

          <template #cell(section)="data">
            <div class="text-uppercase">{{ data.item.section || "-" }}</div>
          </template>

          <template #cell(module)="data">
            <div class="text-uppercase">{{ data.item.module }}</div>
            <div class="text-uppercase" v-if="data.item.section">
              [{{ data.item.section }}]
            </div>
          </template>

          <template #cell(status)="data">
            <span
              :class="`badget-wo-color clickable ${
                Boolean(data.item.on_review) &&
                data.item.status == 'In Filter' &&
                isReviewer &&
                isOnReview &&
                'flashes'
              }`"
              @click="openTimeline(data.item)"
              :style="
                styleColor(
                  data.item.status == 'In Progress' && !data.item.accept_paragon
                    ? '#ff9f43'
                    : data.item.status_color,
                  data.item.status == 'In Filter'
                )
              "
            >
              <span
                v-if="
                  data.item.status == 'In Progress' && !data.item.accept_paragon
                "
              >
                In Paragon
              </span>
              <span v-else>
                {{ data.item.status }}
              </span>
            </span>
          </template>

          <template #cell(deleted_by)="data">
            <div>{{ data.item.deleted_by }}</div>
            <div>{{ data.item.deleted_at | myGlobalDay }}</div>
          </template>

          <template #cell(motive)="data">
            {{
              data.item.motive == "Otros"
                ? data.item.motive_reject_message
                : data.item.motive
            }}
          </template>

          <template #cell(error_name)="data">
            <span
              v-if="data.item.category_ticket !== 'support'"
              class="badget-wo-color clickable"
              @click="showError(data.item.ticket_id)"
              :style="
                isDarkSkin
                  ? `
                    background-color: rgba(${hexToRgb(
                      data.item.error_color || '#FAFAFA'
                    )}, 0.2);
                    color: ${data.item.error_color || '#FAFAFA'};
                  `
                  : `
                    background-color: transparent;
                    color: ${
                      data.item.error_color
                        ? data.item.error_name == 'Rule Conflict'
                          ? '#ff9f43'
                          : data.item.error_color
                        : '#878484'
                    };
                    border: 1px solid ${
                      data.item.error_color
                        ? data.item.error_name == 'Rule Conflict'
                          ? '#ff9f43'
                          : data.item.error_color
                        : '#878484'
                    }
                  `
              "
            >
              {{ data.item.error_name || "Unknown" }}
            </span>
            <span v-else> - </span>
          </template>

          <template #cell(error_review)="data">
            <span
              v-if="data.item.category_ticket !== 'support'"
              class="badget-wo-color"
              :class="isReviewer ? 'clickable' : ''"
              @click="isReviewer && showError(data.item.ticket_id)"
              :style="
                isDarkSkin
                  ? `
                    background-color: rgba(${hexToRgb(
                      data.item.error_color_review || '#FAFAFA'
                    )}, 0.2);
                    color: ${data.item.error_color_review || '#FAFAFA'};
                  `
                  : `
                    background-color: transparent;
                    color: ${
                      data.item.error_color_review
                        ? data.item.error_name_review == 'Rule Conflict'
                          ? '#ff9f43'
                          : data.item.error_color_review
                        : '#878484'
                    };
                    border: 1px solid ${
                      data.item.error_color_review
                        ? data.item.error_name_review == 'Rule Conflict'
                          ? '#ff9f43'
                          : data.item.error_color_review
                        : '#878484'
                    }
                  `
              "
            >
              {{ data.item.error_name_review || "Unknown" }}
            </span>
            <span v-else> - </span>
          </template>

          <template #cell(due_date)="data">
            <div
              v-if="!data.item.due_date"
              class="d-flex align-items-center justify-content-center"
            >
              <span class="text-on-review" style="font-size: 14px">
                {{
                  data.item.status === "In Progress" &&
                  !data.item.accept_paragon
                    ? "In Paragon"
                    : data.item.status
                }}</span
              >
            </div>
            <Timer
              v-else
              :created_at="data.item.created_at"
              :special="data.item.special"
              :due_date="data.item.due_date"
            />
          </template>

          <template #cell(actions)="{ item }">
            <div class="d-flex justify-content-center" style="gap: 10px">
              <feather-icon
                :icon="`${isShowEngSoft ? 'CheckCircleIcon' : 'SendIcon'}`"
                size="18"
                :style="
                  isReviewer && !item.error_id_reviewer
                    ? 'cursor: not-allowed;'
                    : ''
                "
                class="clickable text-success"
                v-b-tooltip.hover.auto="
                  `${
                    isShowEngSoft
                      ? 'Accept'
                      : isReviewer && !item.error_id_reviewer
                      ? 'Select Error Type'
                      : 'Send to Paragon'
                  }`
                "
                v-if="
                  ((item.status === 'In Filter' && isReviewer) ||
                    (isShowEngSoft && !item.accept_paragon)) &&
                  ['In Progress', 'In Filter'].includes(item.status) &&
                  item.deleted_at == null
                "
                @click="sendToParagon(item)"
              />
              <feather-icon
                icon="ArrowLeftCircleIcon"
                size="18"
                class="clickable text-danger"
                v-b-tooltip.hover.auto="`Reject ticket`"
                v-if="
                  (item.status === 'In Progress' &&
                    isShowEngSoft &&
                    isProcess) ||
                  isOnReview
                "
                @click="removeTicket(item.ticket_id)"
              />
              <div
                class="d-flex justify-content-center align-items-center"
                style="gap: 5px"
                v-if="item.status === 'Deployed' && !isShowEngSoft"
              >
                <feather-icon
                  icon="ArrowLeftCircleIcon"
                  size="18"
                  class="clickable text-danger"
                  v-b-tooltip.hover.auto="`Return ticket`"
                  @click="showReturnTicket(item.ticket_id)"
                />
                <feather-icon
                  icon="CheckCircleIcon"
                  size="18"
                  class="clickable text-success"
                  v-b-tooltip.hover.auto="`Complete ticket`"
                  @click="completeTicket(item.ticket_id)"
                />
              </div>
              <feather-icon
                icon="EyeIcon"
                size="18"
                class="clickable"
                v-b-tooltip.hover.auto="`View ticket`"
                :class="
                  (isReviewer &&
                    item.pending == 1 &&
                    item.status == 'In Filter') ||
                  (isShowEngSoft &&
                    item.pending == 1 &&
                    item.owner_id == currentUser.user_id)
                    ? 'text-warning'
                    : 'text-primary'
                "
                @click="viewTicket(item)"
              />
            </div>
          </template>
        </b-table>
      </template>
    </filter-slot>

    <modal-detail-issue
      :description="descriptionSelected"
      :showDescription="showDescription"
      v-if="showDescription"
      @close="closeDescription"
    />

    <ModalReturned
      :ticket_id="ticket_id"
      :showModal="showReturnModal"
      v-if="showReturnModal"
      @close="showReturnModal = false"
      @refresh="refresh"
    />

    <ModalDetails
      ref="modalDetails"
      :ticket_id="ticket_id"
      v-if="modalDetails"
      @refreshKanban="refresh()"
      @close="closeModalDetails()"
    />

    <Timeline
      v-if="showTimeline"
      @close="showTimeline = false"
      :title="`Timeline Status`"
      :subtitle="`Ticket: ${descriptionSelected.ticketId}`"
      :timeLine="timeLine"
    />

    <ModalRejected
      :ticket_id="ticket_id"
      :isReviewer="isReviewer"
      v-if="showRejected"
      @close="showRejected = false"
      @rejected="
        showRejected = false;
        refresh();
      "
    />

    <ModalErrorType
      v-if="showErrorModal"
      ref="modalErrorType"
      :ticketId="ticket_id"
      @close="showErrorModal = false"
      @refresh="refresh()"
    />
  </div>
</template>

<script>
import useAppConfig from "@core/app-config/useAppConfig";
import moment from "moment";
import Timeline from "@/views/commons/utilities/Timeline.vue";
import Timer from "@/views/commons/components/helpdesk/components/Timer.vue";
import FilterSlot from "@/views/crm/views/sales-made/components/slots/FilterSlot.vue";
import ModalReturned from "@/views/commons/components/helpdesk/components/modals/ModalReturned.vue";
import ModalDetails from "./modals/ModalDetailsKanban.vue";
import ModalDetailIssue from "./ModalDetailsIssue.vue";
import Fields from "./fields";
import Filters from "./filters";
import helpdeskService from "@/views/commons/components/helpdesk/services/helpdesk.service";
import ModalRejected from "@/views/commons/components/helpdesk/components/modals/ModalRejected.vue";
import ModalErrorType from "@/views/commons/components/helpdesk/components/modals/ModalErrorType.vue";
import { mapGetters, mapMutations } from "vuex";

export default {
  components: {
    ModalErrorType,
    ModalDetailIssue,
    ModalDetails,
    ModalReturned,
    FilterSlot,
    Timer,
    Timeline,
    ModalRejected,
  },
  data() {
    return {
      tab: 1,
      sortBy: "created_at",
      sortDesc: true,
      status: null,
      arrayColumns: Fields,
      search_input: "",
      start_page: 0,
      total_data: 0,
      paginate: {
        currentPage: 1,
        perPage: 100,
      },
      to_page: 0,
      isBusy: false,
      perPageOptions: [10, 25, 50, 100],
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search ticket...",
        model: "",
      },
      filters: Filters,
      filter: {
        from: null,
        to: null,
        user: null,
        priority: null,
        search: null,
        moduleId: null,
      },
      descriptionSelected: {
        ticketId: null,
        description: null,
        status_color: null,
        status: null,
      },
      showDescription: false,
      showReturnModal: false,
      modalDetails: false,
      ticket_id: null,
      showTimeline: false,
      timeLine: [],
      showRejected: false,
      showErrorModal: false,
      pgUsers: [],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      roles: "HelpdeskStore/G_HELPDESK_ROLES",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    convertEngSoft() {
      return [1, 41].includes(this.currentUser.user_id) && this.moduleId == 12;
    },
    isShowEngSoft() {
      return this.roles.isEngSoft || this.roles.isEngSoftChief;
    },
    isReviewer() {
      return this.roles.isReviewer;
    },
    isProcess() {
      return this.tab == 1;
    },
    isCompleted() {
      return this.tab == 2;
    },
    isRejected() {
      return this.tab == 3;
    },
    isOnReview() {
      return this.tab == 4;
    },
    isOnProcess() {
      return this.tab == 5;
    },
    isOnCompleted() {
      return this.tab == 6;
    },
    isOnRejected() {
      return this.tab == 7;
    },
    isReviewerTab() {
      return (
        this.isOnReview ||
        this.isOnProcess ||
        this.isOnCompleted ||
        this.isOnRejected
      );
    },
    headersTable() {
      this.filters[4].visible = [1, 5].includes(this.tab);
      const isRejected = this.isRejected || this.isOnRejected;
      this.getColumn("module").visible =
        this.isShowEngSoft || this.isReviewerTab;
      this.getColumn("section").visible = !this.getColumn("module").visible;
      this.getColumn("category_ticket").visible = !this.isReviewerTab;
      this.getColumn("status").visible = !isRejected;
      this.getColumn("due_date").visible = this.isProcess;
      this.getColumn("deleted_by").visible = isRejected;
      this.getColumn("motive").visible = isRejected;
      this.getColumn("error_name").visible = this.isShowEngSoft;
      this.getColumn("error_review").visible =
        this.isShowEngSoft || this.isReviewerTab;
      return this.arrayColumns.filter((field) => field.visible);
    },
    ticketClasses() {
      return {
        "text-warning": this.isDarkSkin,
        "text-primary": !this.isDarkSkin,
        "font-weight-bolder": this.isShowEngSoft,
      };
    },
  },
  created() {
    this.init();
    this.getStatuses();
    this.getUsers();
    this.getPgUsers();
    this.loadModules();
    this.filters[2].visible = this.isShowEngSoft;
    this.filters[5].visible = this.isShowEngSoft;
  },
  methods: {
    ...mapMutations({
      refreshCounterSidebar: "SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY",
      refreshTabsCounters: "HelpdeskStore/SET_COUNTER_NOTIFICATIONS",
      refreshTabOnReview: "HelpdeskStore/SET_REVIEWER_COUNTER_NOTIFICATIONS",
      refreshTabOnProcess:
        "HelpdeskStore/SET_REVIEWER_ON_PROCESS_NOTIFICATIONS",
    }),
    init() {
      this.arrayColumns.forEach((field) => {
        if (field.module) {
          if (field.module == this.currentUser.modul_id) {
            field.visible = true;
          } else {
            field.visible = false;
          }
        }
      });
    },
    inTime(created_at) {
      const createdIn48Hours = moment(created_at).add(48, "hours");
      const now = moment();
      return createdIn48Hours.isAfter(now);
    },
    openDescription(item) {
      let { ticket_id, description, status_color, status } = item;
      this.descriptionSelected.ticketId = ticket_id;
      this.descriptionSelected.description = description;
      this.descriptionSelected.status_color = status_color;
      this.descriptionSelected.status = status;
      this.showDescription = true;
      if (item.status_view_messages == 0) {
        item.status_view_messages = 1;
        this.updateStatusViewMessages(item);
      }
    },
    closeDescription() {
      this.showDescription = false;
    },
    rowClass(item) {
      return item?.status == "Deployed" ? "bg-success-hd" : "";
    },
    async myProvider() {
      try {
        const params = {
          tab: this.tab,
          userId: this.filters[3].model,
          moduleId:
            this.isShowEngSoft || this.isReviewerTab
              ? this.filters[2].model
              : this.moduleId,
          from: this.filters[0].model,
          to: this.filters[1].model,
          search: this.filterPrincipal.model,
          status: this.isOnReview ? 1 : this.filters[4].model,
          error: this.filters[5].model,
          eng_soft: this.isShowEngSoft
            ? this.currentUser.user_id
            : this.isOnProcess
            ? 124
            : null,
          per_page: this.paginate.perPage,
          orden: "desc",
          page: this.paginate.currentPage,
        };

        const response = await helpdeskService.getTicketsOnReview(params);
        let { data, from, current_page, to, last_page, total } = response.data;
        const items = data;
        this.start_page = from;
        this.paginate.currentPage = current_page;
        this.next_page = this.start_page + 1;
        this.end_page = last_page;
        this.to_page = to;
        this.total_data = total;
        return items || [];
      } catch (e) {
        console.error(e);
        return [];
      }
    },
    async getPgUsers() {
      const { data } = await helpdeskService.getPgUsers();
      this.pgUsers = [...data, ...this.pgUsers];
    },
    async getUsers() {
      const params = {
        roles: "[]",
        type: "0",
      };
      const module_id = this.isShowEngSoft
        ? this.filters[2].model
        : this.currentUser.modul_id;
      if (!module_id) return;
      const response = await helpdeskService.getUsers(module_id, params);
      this.filters[3].options = [];
      response.data.forEach((item) => {
        this.filters[3].options.push({
          value: item.id,
          label: item.user_name,
        });
      });
    },
    refresh() {
      this.$refs.refTickets.refresh();
      this.refreshCounterNotifications();
    },
    async getStatuses() {
      this.filters[4].options = [];
      const response = await helpdeskService.getStatuses();
      response.data.forEach((item) => {
        this.filters[4].options.push({
          value: item.id,
          label: item.name,
        });
      });
    },
    async loadModules() {
      this.filters[2].options = [];
      const response = await helpdeskService.getModules();
      response.data.forEach((item) => {
        this.filters[2].options.push({
          value: item.id,
          label: item.name,
        });
      });
    },
    async updateStatusViewMessages(item) {
      if (item.sender_message_id == this.currentUser.user_id) return;
      const params = { ticket_id: item.ticket_id };
      await helpdeskService.updateStatusViewMessages(params);
      this.refreshCounterNotifications();
    },
    async refreshCounterNotifications() {
      const params = {
        modul_id: this.moduleId,
        user_id: this.currentUser.user_id,
      };
      const paramsReview = {
        is_reviewer: 1,
        user_id: 124,
      };
      const { data } = await helpdeskService.getCounterNotifications(params);
      const { data: counter } = await helpdeskService.getCountTab(params);

      if (this.isProcess) {
        this.refreshTabsCounters(counter.total);
      } else if (this.isOnReview || this.isOnProcess) {
        const { data: counterReviewer } = await helpdeskService.getCountTab(
          paramsReview
        );
        const { data: counterReviewerOnProcess } =
          await helpdeskService.getCountTab({
            ...paramsReview,
            on_process: 1,
          });
        this.refreshTabOnReview(counterReviewer.total);
        this.refreshTabOnProcess(counterReviewerOnProcess.total);
      }

      if (Object.keys(data).length > 0) {
        const payload = {
          routeName: this.$route.name,
          tag: data.total,
        };
        this.refreshCounterSidebar(payload);
      }
    },
    async removeTicket(ticket) {
      this.ticket_id = ticket;
      this.showRejected = true;
    },
    viewTicket(item) {
      this.ticket_id = item.ticket_id;
      this.modalDetails = true;
      this.$set(item, "pending", 0);
      this.refreshCounterNotifications();
    },
    closeModalDetails() {
      this.modalDetails = false;
    },
    showReturnTicket(ticked_id) {
      this.showReturnModal = true;
      this.ticket_id = ticked_id;
    },
    showError(id) {
      this.ticket_id = id;
      this.showErrorModal = true;
    },
    refreshData(tab) {
      this.tab = tab;
      this.filters.forEach((item) => {
        item.model = null;
      });
      this.$refs.refTickets.refresh();
    },
    changeModule(type) {
      if (type == "changeModule") this.getUsers();
    },
    async openTimeline(ticket) {
      this.descriptionSelected.ticketId = ticket.ticket_id;
      this.descriptionSelected.status_color = ticket.status_color;
      this.descriptionSelected.status = ticket.status;
      const data = await helpdeskService.getTrackingTicket({
        ticket_id: ticket.id,
      });
      this.timeLine = data.map((item) => {
        return {
          title: item.name,
          date: item.created_at,
          active: item.ticket_id == ticket.id,
          review: item.review,
        };
      });
      this.showTimeline = true;
    },
    async completeTicket(ticket) {
      const confirm = await this.showConfirmSwal(
        "Are you sure you want to complete this ticket?"
      );
      if (!confirm.value) return;
      const params = {
        ticket_id: ticket,
        user_id: this.currentUser.user_id,
      };
      await helpdeskService.completeTicket(params);
      this.$refs.refTickets.refresh();
    },
    async returnTicket(ticket) {
      const confirm = await this.showConfirmSwal(
        "Are you sure you want to return this ticket?"
      );
      if (!confirm.value) return;
      const params = {
        ticket_id: ticket,
        user_id: this.currentUser.user_id,
      };
      await helpdeskService.returnTicket(params);
      this.$refs.refTickets.refresh();
    },
    styleColor(color = "#878484", type) {
      const colour = this.hexToRgb(color);

      if (this.isDarkSkin) {
        return `
              background-color: rgba(${colour}, 0.2);
              color: ${color};
          `;
      } else {
        if (type) {
          color = "#878484";
        }
        return `
                  background-color: transparent;
                  color: ${color};
                  border: 1px solid ${color};
              `;
      }
    },
    colorStatus(data) {
      if (
        Boolean(data.item.on_review) &&
        data.item.status == "In Filter" &&
        this.isReviewer
      ) {
        return {
          backgroundColor: `rgba(${this.hexToRgb(
            data.item.status_color
          )}, 0.6)`,
          color: `#000000`,
          border: "1px solid #000000",
        };
      } else {
        return {
          backgroundColor: `rgba(${this.hexToRgb(
            data.item.status_color
          )}, 0.2)`,
          color: data.item.status_color,
        };
      }
    },
    async sendToParagon({ ticket_id, error_id_reviewer }) {
      if (this.isReviewer && !error_id_reviewer) {
        return;
      }
      try {
        const confirm = await this.showConfirmSwal(
          "Are you sure to send to Paragon?",
          "You won't be able to revert this!"
        );
        if (!confirm.value) {
          return;
        }
        this.addPreloader();
        const params = {
          ticketId: ticket_id,
          statusId: 2,
          userId: this.currentUser.user_id,
          isReviewer: this.isReviewer,
        };
        await helpdeskService.updateStatusTicket(params);
        this.showGenericToast({
          title: "Success",
          text: "Ticket sent to Paragon successfully",
        });
        this.refresh();
      } catch (e) {
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
      }
    },
    getColumn(key) {
      return this.arrayColumns.find((item) => item.key === key);
    },
  },
};
</script>

<style lang="scss" scoped>
.badget-wo-color {
  padding: 3px 6px;
  border-radius: 5px;
  font-weight: bold;
}

.text-on-review {
  color: #00d25b !important;
  font-weight: 700;
}

.flashes {
  animation-name: parpadeo;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name: parpadeo;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}

@-moz-keyframes parpadeo {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes parpadeo {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes parpadeo {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
<style lang="scss">
.table-new-customization table.detail-hd {
  .bg-pendings {
    td {
      background: rgba(234, 249, 255, 1) !important ;
    }
    td:last-child {
      background: rgba(234, 249, 255, 1) !important ;
    }
  }
  .bg-success-hd {
    td {
      background: rgba(234, 255, 242) !important;
    }
    td:last-child {
      background: rgba(234, 255, 242) !important;
    }
  }
  .dark-layout & {
    .bg-pendings {
      td {
        background: rgba(58, 114, 234, 0.2) !important;
      }
      td:last-child {
        background: rgba(58, 114, 234, 0.2) !important;
      }
    }
    .bg-success-hd {
      td {
        background: rgba(61, 234, 58, 0.2) !important;
      }
      td:last-child {
        background: rgba(61, 234, 58, 0.2) !important;
      }
    }
  }
}
</style>
