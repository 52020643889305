<template>
  <div>
    <header-slot>
      <template #actions>
        <b-button
          variant="primary"
          @click="newIssueShow = true"
          v-if="(!showKanban && requestPassword) || currentUser.eng_soft"
        >
          <b-icon icon="plus"></b-icon>
          New Ticket</b-button
        >
        <b-button
          variant="success"
          class="ml-2"
          @click="
            showKanban = !showKanban;
            tabActive == 1;
          "
          v-if="showSuper"
        >
          <b-icon icon="view-list"></b-icon>
          <span v-text="!showKanban ? ' Kanban' : ' List'"></span>
        </b-button>
      </template>
    </header-slot>
    <help-desk-Kanban v-if="showKanban" />
    <div v-else>
      <template v-if="requestPassword">
        <b-nav card-header pills class="m-0" ref="helpdeskTabs">
          <b-nav-item
            exact
            :active="tabActive === 1"
            :link-classes="['px-3', bgTabsNavs]"
            exact-active-class="active"
            @click="setTabHelper(1)"
          >
            <div
              style="height: 17px"
              class="d-flex align-items-center justify-content-center"
            >
              Process
              <span v-if="counter > 0" class="ml-1">
                <b-badge pill variant="danger">
                  {{ counter > 99 ? "99+" : counter }}
                </b-badge>
              </span>
            </div>
          </b-nav-item>
          <b-nav-item
            exact
            :active="tabActive === 2"
            exact-active-class="active"
            :link-classes="['px-3', bgTabsNavs]"
            @click="setTabHelper(2)"
          >
            <div
              style="height: 17px"
              class="d-flex align-items-center justify-content-center"
            >
              Completed
            </div>
          </b-nav-item>
          <b-nav-item
            exact
            :active="tabActive === 3"
            exact-active-class="active"
            :link-classes="['px-3', bgTabsNavs]"
            @click="setTabHelper(3)"
          >
            <div
              style="height: 17px"
              class="d-flex align-items-center justify-content-center"
            >
              Rejected
            </div>
          </b-nav-item>
          <b-nav-item
            v-if="currentUser.is_reviewer"
            exact
            :active="[4, 5, 6, 7].includes(tabActive)"
            :link-classes="['px-3', bgTabsNavs]"
            exact-active-class="active"
            @click="
              setTabHelper(4);
              $refs.helpDeskGrid.refreshData(4);
            "
          >
            <div
              style="height: 17px"
              class="d-flex align-items-center justify-content-center"
            >
              Paragon Reviewer
              <span v-if="onReviewCount > 0" class="ml-1">
                <b-badge pill variant="danger">
                  {{ onReviewCount > 99 ? "99+" : onReviewCount }}
                </b-badge>
              </span>
            </div>
          </b-nav-item>
        </b-nav>
        <b-card
          no-body
          class="border-top-primary border-3 border-table-radius p-1"
        >
          <b-tabs
            v-if="[4, 5, 6, 7].includes(tabActive)"
            active-tab-class="p-0 "
            pills
            nav-class="mb-0"
            active-nav-item-class="bg-info box-shadow-info border-info info"
          >
            <b-tab
              :active="tabActive === 4"
              :title-link-class="[bgTabsNavs, 'sub-tab px-3']"
              @click="setTabHelper(4)"
            >
              <template #title>
                <div
                  style="height: 18px !important"
                  class="d-flex align-items-center justify-content-center"
                >
                  On Review
                  <span v-if="onReviewCount > 0" class="ml-1">
                    <b-badge pill variant="danger">
                      {{ onReviewCount > 99 ? "99+" : onReviewCount }}
                    </b-badge>
                  </span>
                </div>
              </template>
            </b-tab>

            <b-tab
              :active="tabActive === 5"
              :title-link-class="[bgTabsNavs, 'sub-tab px-3']"
              @click="setTabHelper(5)"
            >
              <template #title>
                <div
                  style="height: 18px !important"
                  class="d-flex align-items-center justify-content-center"
                >
                  On Process
                  <span v-if="onProcessCount > 0" class="ml-1">
                    <b-badge pill variant="primary">
                      {{ onProcessCount > 99 ? "99+" : onProcessCount }}
                    </b-badge>
                  </span>
                </div>
              </template>
            </b-tab>

            <b-tab
              :active="tabActive === 6"
              :title-link-class="[bgTabsNavs, 'sub-tab px-3']"
              @click="setTabHelper(6)"
            >
              <template #title>
                <div
                  style="height: 18px !important"
                  class="d-flex align-items-center justify-content-center"
                >
                  Completed
                </div>
              </template>
            </b-tab>
            <b-tab
              :active="tabActive === 7"
              :title-link-class="[bgTabsNavs, 'sub-tab px-3']"
              @click="setTabHelper(7)"
            >
              <template #title>
                <div
                  style="height: 18px !important"
                  class="d-flex align-items-center justify-content-center"
                >
                  Rejected
                </div>
              </template>
            </b-tab>
          </b-tabs>
          <b-card
            no-body
            :class="`${
              [4, 5, 6, 7].includes(tabActive) && 'border-top-info border-3'
            }  p-0 border-table-radius`"
          >
            <help-desk-grid ref="helpDeskGrid"></help-desk-grid>
          </b-card>
        </b-card>
      </template>
      <template v-else>
        <!-- container centrado para login bootstrap vue -->
        <b-container>
          <b-card class="offset-md-4 col-md-4 mt-5">
            <div class="text-center">
              <b-img
                src="/assets/images/helpdesk/developer.png"
                alt="Logo"
                class="img-fluid"
              />
              <div class="insert-title">Please insert password</div>
            </div>
            <b-card-body>
              <b-row>
                <b-col class="offset-md-1 col-md-10">
                  <ValidationObserver ref="form">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <b-form-group>
                        <label class="label-class" for="pName">Password:</label>
                        <b-form-input
                          @keyup.enter="validatePassword()"
                          type="password"
                          v-model="inputPassword"
                          placeholder="Password"
                          :class="{
                            'border border-danger': errors[0] || errorPassword,
                          }"
                        ></b-form-input>
                        <small
                          class="text-danger"
                          v-if="errors[0] || errorPassword"
                        >
                          This field {{ errors[0] }} or Password is incorrect
                        </small>
                      </b-form-group>
                    </ValidationProvider>
                  </ValidationObserver>
                </b-col>
                <b-col cols="12" class="text-center">
                  <b-button variant="primary" @click="validatePassword()">
                    Check Password
                  </b-button>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-container>
      </template>
    </div>

    <NewIssue
      v-if="newIssueShow"
      @closeModal="newIssueShow = false"
      @inserted="
        newIssueShow = false;
        refresh();
      "
    ></NewIssue>
  </div>
</template>

<script>
import HelpDeskGrid from "./components/HelpdeskGrid.vue";
import HelpDeskKanban from "./components/HelpdeskKanban.vue";
import NewIssue from "./components/NewIssue.vue";
import helpdeskService from "@/views/commons/components/helpdesk/services/helpdesk.service";
import { mapGetters, mapMutations } from "vuex";
export default {
  components: {
    HelpDeskGrid,
    HelpDeskKanban,
    NewIssue,
  },
  data() {
    return {
      newIssueShow: false,
      modalMessage: false,
      categoryIssue: null,
      sectionIssue: null,
      passwordGrid: "waiting",
      inputPassword: "",
      isValidPassword: false,
      sendPassword: false,
      showKanban: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      counter: "HelpdeskStore/G_COUNTER_NOTIFICATIONS",
      onReviewCount: "HelpdeskStore/G_REVIEWER_COUNTER_NOTIFICATIONS",
      onProcessCount: "HelpdeskStore/G_REVIEWER_ON_PROCESS_NOTIFICATIONS",
      rolesOnHelpdesk: "HelpdeskStore/G_HELPDESK_ROLES",
      tabActive: "HelpdeskStore/G_TAB_ACTIVE",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    errorPassword() {
      return this.sendPassword && !this.isValidPassword;
    },
    showSuper() {
      return (
        (this.currentUser.eng_soft ||
          this.isCeo ||
          this.isChief ||
          this.currentUser.user_id == 41) &&
        this.moduleId == 12
      );
    },
    requestPassword() {
      if (
        this.currentUser.role_id == 1 ||
        this.currentUser.role_id == 2 ||
        this.currentUser.role_id == 17 ||
        this.isAssistantSupervisor ||
        this.isTeamLeader ||
        this.currentUser.eng_soft ||
        this.currentUser.user_id == 243
      ) {
        return true;
      }
      return this.isValidPassword;
    },
  },
  created() {
    this.getCountTab();
    if (this.currentUser.is_reviewer) {
      this.getCountTabPara();
    }

    // start roles
    const payload = {
      isEngSoftChief:
        [1, 41].includes(this.currentUser.user_id) && this.moduleId == 12,
      isReviewer: Boolean(this.currentUser.is_reviewer),
      isDevLeader: Boolean(this.currentUser.dev_leader),
      isEngSoft: Boolean(this.currentUser.eng_soft),
    };
    this.getRoles(payload);
  },
  beforeDestroy() {
    this.setTabHelper(1);
  },
  methods: {
    ...mapMutations({
      refreshTabsCounters: "HelpdeskStore/SET_COUNTER_NOTIFICATIONS",
      refreshTabOnReview: "HelpdeskStore/SET_REVIEWER_COUNTER_NOTIFICATIONS",
      refreshTabOnProcess:
        "HelpdeskStore/SET_REVIEWER_ON_PROCESS_NOTIFICATIONS",
      getRoles: "HelpdeskStore/SET_HELPDESK_ROLES",
      setTabActive: "HelpdeskStore/SET_TAB_ACTIVE",
    }),
    setTabHelper(tab) {
      this.setTabActive(tab);
      this.$refs.helpDeskGrid.refreshData(tab);
    },
    async getCountTabPara() {
      const params = {
        user_id: 124,
        is_reviewer: 1,
      };
      const onReview = await helpdeskService.getCountTab({ ...params });
      const onProcess = await helpdeskService.getCountTab({
        ...params,
        on_process: 1,
      });
      this.refreshTabOnReview(onReview.data.total);
      this.refreshTabOnProcess(onProcess.data.total);
    },
    async getCountTab() {
      const params = {
        user_id: this.currentUser.user_id,
        modul_id: this.currentUser.modul_id,
      };
      const result = await helpdeskService.getCountTab(params);
      this.refreshTabsCounters(result.data.total);
    },
    refresh() {
      this.$refs.helpDeskGrid.refresh();
    },
    async validatePassword() {
      if (this.inputPassword == "" || this.inputPassword == null) {
        return;
      }
      try {
        this.addPreloader();
        const params = {
          module_id: this.currentUser.modul_id,
          password: this.inputPassword,
        };
        const data = await helpdeskService.getPassword(params);
        this.removePreloader();
        if (data > 0) {
          this.isValidPassword = true;
        } else {
          this.isValidPassword = false;
          this.inputPassword = "";
          swal.fire("Oops", "Password is incorrect", "warning");
        }
      } catch {
        swal.fire("Oops", "Something went wrong", "warning");
      }
    },
  },
};
</script>

<style scoped>
.insert-title {
  font-size: 1.5rem;
  font-weight: bold;
}
.img-fluid {
  max-width: 50%;
  height: auto;
}
.label-class {
  font-size: 1rem;
  font-weight: bold;
}
.badge-chrome {
  top: -23px;
}
</style>
