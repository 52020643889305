<template>
  <b-modal
    ref="showNewIssue"
    modal-class="modal-top-0"
    size="lg"
    title-class="h3 text-white font-weight-bolder"
    body-class="body-modal-issue"
    centered
    @hidden="closeModal()"
    title="Create a new ticket"
    no-close-on-backdrop
    no-close-on-esc
  >
    <b-form ref="form">
      <ValidationObserver ref="validForm">
        <b-row class="my-0">
          <ValidationProvider
            rules="required"
            v-slot="{ errors }"
            class="col-6"
          >
            <b-form-group
              id="input-group-module"
              label="Module"
              label-for="input-module"
              description="Module of the ticket"
            >
              <b-form-select
                v-model="moduleId"
                :options="modulesOptions"
                disabled
                class="select-icon-none bg-color-white font-bureau-style border-hover-p"
                :class="{ 'is-invalid': errors[0] }"
                title="Modules"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled
                    >Please select a Module
                  </b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </ValidationProvider>
          <ValidationProvider
            rules="required"
            v-slot="{ errors }"
            class="col-6"
          >
            <b-form-group
              id="input-group-section"
              label="Category"
              label-for="input-section"
              description="Category of the ticket"
            >
              <b-form-select
                id="input-priority"
                v-model="issue.category"
                :options="categories"
                @change="getTitlesOfTickets()"
                class="select-icon-none bg-color-white font-bureau-style border-hover-p"
                :class="{ 'is-invalid': errors[0] }"
                required
              >
              </b-form-select>
            </b-form-group>
          </ValidationProvider>
        </b-row>
        <ValidationProvider
          :rules="issue.category == 1 ? 'required' : ''"
          v-slot="{ errors }"
          v-if="issue.category == 1"
        >
          <b-form-group
            id="input-group-section"
            label="Section"
            label-for="input-section"
            description="Section of the ticket"
          >
            <b-form-select
              id="input-priority"
              v-model="issue.sectionName"
              :options="sections"
              :disabled="issue.category == 2 || issue.category == null"
              @change="getTitlesOfTickets()"
              class="select-icon-none bg-color-white font-bureau-style border-hover-p"
              :class="{ 'is-invalid': errors[0] }"
            >
            </b-form-select>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider rules="required" v-slot="{ errors }">
          <b-form-group
            v-if="issue.category == 2"
            id="input-group-section"
            label="Title"
            label-for="input-section"
          >
            <b-input-group>
              <b-form-select
                id="input-priority"
                v-model="issue.messageId"
                :options="defaultDescription"
                @change="changeDefaultDescription()"
                :disabled="validateTitleMessage != null"
                class="select-icon-none bg-color-white font-bureau-style border-hover-p"
                :class="{ 'is-invalid': errors[0] }"
                required
              >
              </b-form-select>
              <template #append>
                <b-button
                  v-if="currentUser.eng_soft"
                  variant="primary"
                  :disabled="validateTitleMessage != null"
                  style="height: 2.714rem"
                  @click="openModalNewTitle()"
                  >New

                  <feather-icon icon="PlusIcon" size="15" />
                </b-button>
              </template>
            </b-input-group>
            <small v-text="validateTitleMessage" class="text-danger"></small>
          </b-form-group>
          <b-form-group
            v-if="issue.category == 1 || issue.messageId === 999"
            id="input-group-section"
            :label="issue.category == 1 ? 'Title' : 'Specific title'"
            label-for="input-section"
          >
            <b-input-group>
              <b-input
                id="input-title"
                v-model="issue.title"
                type="text"
                required
                class="bg-color-white font-bureau-style border-hover-p"
                :class="{ 'is-invalid': errors[0] }"
              />
            </b-input-group>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider rules="required" v-slot="{ errors }">
          <b-form-group
            id="input-group-description"
            label="Description"
            label-for="input-description"
            description="Long ticket description - Please be as descriptive as possible so our team can reproduce your ticket."
          >
            <b-textarea
              id="input-description"
              v-model="issue.description"
              type="text"
              required
              class="bg-color-white font-bureau-style border-hover-p"
              rows="5"
              :class="{ 'is-invalid': errors[0] }"
            ></b-textarea>
          </b-form-group>
        </ValidationProvider>
      </ValidationObserver>
      <drag-and-drop v-model="files" :filesArray="files"></drag-and-drop>
    </b-form>
    <template #modal-footer>
      <div style="display: flex; justify-content: end; align-items: center">
        <b-button class="rounded mr-2 btn-ui-orange" @click="closeModal()"
          >Cancel</b-button
        >
        <b-button
          @click="sendIssue()"
          variant="primary"
          class="rounded btn-ui-green"
          >Send</b-button
        >
      </div>
    </template>
    <NewTitleTicket
      v-if="showNewTitle"
      :categoryIssue="issue.category"
      :sectionIsuue="issue.sectionName"
      @close="showNewTitle = false"
      @inserted="
        showNewTitle = false;
        getTitlesOfTickets();
      "
    />
  </b-modal>
</template>

<script>
import moment from "moment";
import DragAndDrop from "@/views/commons/utilities/DragAndDrop";
import helpdeskService from "@/views/commons/components/helpdesk/services/helpdesk.service";
import NewTitleTicket from "@/views/commons/components/helpdesk/components/NewTitleTicket.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    DragAndDrop,
    NewTitleTicket,
  },
  data() {
    return {
      files: [],
      issue: {
        messageId: null,
        title: "",
        description: "",
        priority: 1,
        dateTime: null,
        userId: null,
        sectionName: null,
        moduleId: null,
        category: null,
      },
      validateTitleMessage: "Select a category",
      quillOptions: {
        theme: "snow",
      },
      categories: [
        { text: "Select", value: null, disabled: true },
        { text: "Software", value: 1 },
        { text: "Support", value: 2 },
      ],
      sections: [{ text: "Select", value: null, disabled: true }],
      modulesOptions: [],
      defaultDescription: [{ text: "Select", value: null, disabled: true }],
      showNewTitle: false,
    };
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  mounted() {
    this.toggleModal("showNewIssue");
  },
  created() {
    this.init();
    this.loadModules();
    this.getSections();
  },
  methods: {
    init() {
      this.issue.dateTime = moment().format("YYYY-MM-DD HH:mm:ss");
      this.issue.userId = this.currentUser.user_id;
      this.issue.moduleId = this.moduleId;
    },

    closeModal() {
      this.$emit("closeModal");
    },

    async sendIssue() {
      const valid = await this.$refs.validForm.validate();
      if (!valid) return;

      const confirm = await this.showConfirmSwal(
        "Are you sure you want to send this ticket?"
      );

      if (!confirm.isConfirmed) return;

      try {
        this.addPreloader();
        let formData = new FormData();
        this.files.forEach((file) => {
          formData.append("files[]", file);
        });
        for (let key in this.issue) {
          formData.append(key, this.issue[key]);
        }
        await helpdeskService.registerTicket(formData);
        this.showSuccessSwal("Ticket sent successfully");
        this.$emit("inserted");
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    async loadModules() {
      const response = await helpdeskService.getModules();
      this.modulesOptions = response.data.map((module) => {
        return {
          text: module.name,
          value: module.id,
        };
      });
    },

    async getSections() {
      const response = await helpdeskService.getSections(this.moduleId);
      response.data.forEach((item) => {
        this.sections.push({
          value: item.id,
          text: item.name,
        });
      });
    },

    generateUniqueId() {
      return Date.now() + Math.floor(Math.random() * 1000);
    },

    async getTitlesOfTickets() {
      if (this.issue.category == 1) {
        return;
      }
      this.addPreloader();
      this.issue.messageId = null;
      this.issue.title = "";
      this.issue.description = "";
      this.issue.sectionName =
        this.issue.category == 1 ? this.issue.sectionName : null;
      try {
        const params = {
          category: this.issue.category,
          section_id: this.issue.sectionName,
        };
        const response = await helpdeskService.getDefaultDescription(params);
        this.defaultDescription = [];
        this.defaultDescription.push({
          value: null,
          text: "Select",
          disabled: true,
        });
        response.data.forEach((element) => {
          this.defaultDescription.push({
            value: element.id,
            text: element.title,
            message: element.message,
          });
        });
        this.validateTitleMessage = null;
        this.removePreloader();
      } catch (e) {
        console.log(e);
        this.removePreloader();
      }
    },

    openModalNewTitle() {
      this.showNewTitle = true;
    },

    changeDefaultDescription() {
      this.defaultDescription.forEach((item) => {
        if (this.issue.messageId == item.value && item.value != 999) {
          this.issue.title = item.text;
          this.issue.description = item.message;
        }
      });
    },
  },
};
</script>
