export default [
  {
    type: "datepicker",
    cols: 6,
    margin: true,
    showLabel: true,
    label: "From",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
  },
  {
    type: "datepicker",
    cols: 6,
    margin: true,
    showLabel: true,
    label: "To",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
  },
  {
    type: "select",
    cols: 12,
    margin: true,
    showLabel: true,
    visible: true,
    label: "Select Module",
    placeholder: "",
    class: "font-small-3",
    model: null,
    options: [
      {
        value: 0,
        label: "All",
      },
    ],
    reduce: "value",
    selectText: "label",
    emitEvent: true,
    typeEvent: "changeModule",
  },
  {
    type: "select",
    cols: 12,
    margin: true,
    showLabel: true,
    visible: true,
    label: "Select Users",
    placeholder: "",
    class: "font-small-3",
    model: null,
    options: [
      {
        value: 0,
        label: "All",
      },
    ],
    reduce: "value",
    selectText: "label",
  },
  {
    type: "select",
    cols: 12,
    margin: true,
    showLabel: true,
    visible: true,
    label: "Select status",
    placeholder: "",
    class: "font-small-3",
    model: null,
    options: [
      {
        value: 0,
        label: "All",
      },
    ],
    reduce: "value",
    selectText: "label",
  },
  {
    type: "select",
    cols: 12,
    margin: true,
    showLabel: true,
    visible: true,
    label: "Select error type",
    placeholder: "",
    class: "font-small-3",
    model: 0,
    options: [
      {
        value: 0,
        label: "All",
      },
      {
        value: 1,
        label: "Bug"
      },
      {
        value: 2,
        label: "New Implementation"
      },
      {
        value: 3,
        label: "Rule Conflict"
      }
    ],
    reduce: "value",
    selectText: "label",
  }
]
