var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('filter-slot',{staticClass:"filter-slot-new-customization",attrs:{"filter":_vm.filters,"filter-principal":_vm.filterPrincipal,"total-rows":_vm.total_data,"paginate":_vm.paginate,"start-page":_vm.start_page,"to-page":_vm.to_page,"send-multiple-sms":false,"show-minimal":"","input-width":"60"},on:{"reload":function($event){return _vm.$refs.refTickets.refresh()},"onSelectChange":_vm.changeModule},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('b-table',{ref:"refTickets",staticClass:"blue-scrollbar position-relative table-new-customization",attrs:{"items":_vm.myProvider,"fields":_vm.headersTable,"primary-key":"id","responsive":"sm","show-empty":"","small":"","sticky-header":"65vh","table-class":"detail-hd","busy":_vm.isBusy,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"current-page":_vm.paginate.currentPage,"per-page":_vm.paginate.perPage,"filter":_vm.search_input,"tbody-tr-class":_vm.rowClass},on:{"update:busy":function($event){_vm.isBusy=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(ticket)",fn:function(data){return [_c('div',{staticClass:"clickable",class:_vm.ticketClasses,on:{"click":function($event){return _vm.viewTicket(data.item)}}},[_vm._v(" "+_vm._s(data.item.title)+" "),_c('br'),_vm._v(" "+_vm._s(data.item.ticket_id)+" ")])]}},{key:"cell(binnacle)",fn:function(data){return [_c('feather-icon',{staticClass:"clickable",class:data.item.status_view_messages == 0 &&
              data.item.sender_message_id != _vm.currentUser.user_id
                ? 'text-warning flashes'
                : 'text-secondary text-muted',attrs:{"icon":"BookOpenIcon","size":"18"},on:{"click":function($event){return _vm.openDescription(data.item)}}})]}},{key:"cell(user)",fn:function(data){return [_c('div',[_vm._v(_vm._s(data.item.user))]),_c('div',[_vm._v(_vm._s(_vm._f("myGlobalDay")(data.item.created_at)))])]}},{key:"cell(category_ticket)",fn:function(data){return [(data.item.category_ticket == 'support')?_c('b-badge',{staticClass:"text-uppercase",attrs:{"variant":"info"}},[_vm._v(_vm._s(data.item.category_ticket))]):_c('b-badge',{staticClass:"text-uppercase",attrs:{"variant":"warning"}},[_vm._v(_vm._s(data.item.category_ticket))])]}},{key:"cell(section)",fn:function(data){return [_c('div',{staticClass:"text-uppercase"},[_vm._v(_vm._s(data.item.section || "-"))])]}},{key:"cell(module)",fn:function(data){return [_c('div',{staticClass:"text-uppercase"},[_vm._v(_vm._s(data.item.module))]),(data.item.section)?_c('div',{staticClass:"text-uppercase"},[_vm._v(" ["+_vm._s(data.item.section)+"] ")]):_vm._e()]}},{key:"cell(status)",fn:function(data){return [_c('span',{class:("badget-wo-color clickable " + (Boolean(data.item.on_review) &&
              data.item.status == 'In Filter' &&
              _vm.isReviewer &&
              _vm.isOnReview &&
              'flashes')),style:(_vm.styleColor(
                data.item.status == 'In Progress' && !data.item.accept_paragon
                  ? '#ff9f43'
                  : data.item.status_color,
                data.item.status == 'In Filter'
              )),on:{"click":function($event){return _vm.openTimeline(data.item)}}},[(
                data.item.status == 'In Progress' && !data.item.accept_paragon
              )?_c('span',[_vm._v(" In Paragon ")]):_c('span',[_vm._v(" "+_vm._s(data.item.status)+" ")])])]}},{key:"cell(deleted_by)",fn:function(data){return [_c('div',[_vm._v(_vm._s(data.item.deleted_by))]),_c('div',[_vm._v(_vm._s(_vm._f("myGlobalDay")(data.item.deleted_at)))])]}},{key:"cell(motive)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.motive == "Otros" ? data.item.motive_reject_message : data.item.motive)+" ")]}},{key:"cell(error_name)",fn:function(data){return [(data.item.category_ticket !== 'support')?_c('span',{staticClass:"badget-wo-color clickable",style:(_vm.isDarkSkin
                ? ("\n                  background-color: rgba(" + (_vm.hexToRgb(
                    data.item.error_color || '#FAFAFA'
                  )) + ", 0.2);\n                  color: " + (data.item.error_color || '#FAFAFA') + ";\n                ")
                : ("\n                  background-color: transparent;\n                  color: " + (data.item.error_color
                      ? data.item.error_name == 'Rule Conflict'
                        ? '#ff9f43'
                        : data.item.error_color
                      : '#878484') + ";\n                  border: 1px solid " + (data.item.error_color
                      ? data.item.error_name == 'Rule Conflict'
                        ? '#ff9f43'
                        : data.item.error_color
                      : '#878484') + "\n                ")),on:{"click":function($event){return _vm.showError(data.item.ticket_id)}}},[_vm._v(" "+_vm._s(data.item.error_name || "Unknown")+" ")]):_c('span',[_vm._v(" - ")])]}},{key:"cell(error_review)",fn:function(data){return [(data.item.category_ticket !== 'support')?_c('span',{staticClass:"badget-wo-color",class:_vm.isReviewer ? 'clickable' : '',style:(_vm.isDarkSkin
                ? ("\n                  background-color: rgba(" + (_vm.hexToRgb(
                    data.item.error_color_review || '#FAFAFA'
                  )) + ", 0.2);\n                  color: " + (data.item.error_color_review || '#FAFAFA') + ";\n                ")
                : ("\n                  background-color: transparent;\n                  color: " + (data.item.error_color_review
                      ? data.item.error_name_review == 'Rule Conflict'
                        ? '#ff9f43'
                        : data.item.error_color_review
                      : '#878484') + ";\n                  border: 1px solid " + (data.item.error_color_review
                      ? data.item.error_name_review == 'Rule Conflict'
                        ? '#ff9f43'
                        : data.item.error_color_review
                      : '#878484') + "\n                ")),on:{"click":function($event){_vm.isReviewer && _vm.showError(data.item.ticket_id)}}},[_vm._v(" "+_vm._s(data.item.error_name_review || "Unknown")+" ")]):_c('span',[_vm._v(" - ")])]}},{key:"cell(due_date)",fn:function(data){return [(!data.item.due_date)?_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('span',{staticClass:"text-on-review",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(data.item.status === "In Progress" && !data.item.accept_paragon ? "In Paragon" : data.item.status))])]):_c('Timer',{attrs:{"created_at":data.item.created_at,"special":data.item.special,"due_date":data.item.due_date}})]}},{key:"cell(actions)",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"gap":"10px"}},[(
                ((item.status === 'In Filter' && _vm.isReviewer) ||
                  (_vm.isShowEngSoft && !item.accept_paragon)) &&
                ['In Progress', 'In Filter'].includes(item.status) &&
                item.deleted_at == null
              )?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.auto",value:(
                ("" + (_vm.isShowEngSoft
                    ? 'Accept'
                    : _vm.isReviewer && !item.error_id_reviewer
                    ? 'Select Error Type'
                    : 'Send to Paragon'))
              ),expression:"\n                `${\n                  isShowEngSoft\n                    ? 'Accept'\n                    : isReviewer && !item.error_id_reviewer\n                    ? 'Select Error Type'\n                    : 'Send to Paragon'\n                }`\n              ",modifiers:{"hover":true,"auto":true}}],staticClass:"clickable text-success",style:(_vm.isReviewer && !item.error_id_reviewer
                  ? 'cursor: not-allowed;'
                  : ''),attrs:{"icon":("" + (_vm.isShowEngSoft ? 'CheckCircleIcon' : 'SendIcon')),"size":"18"},on:{"click":function($event){return _vm.sendToParagon(item)}}}):_vm._e(),(
                (item.status === 'In Progress' &&
                  _vm.isShowEngSoft &&
                  _vm.isProcess) ||
                _vm.isOnReview
              )?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.auto",value:("Reject ticket"),expression:"`Reject ticket`",modifiers:{"hover":true,"auto":true}}],staticClass:"clickable text-danger",attrs:{"icon":"ArrowLeftCircleIcon","size":"18"},on:{"click":function($event){return _vm.removeTicket(item.ticket_id)}}}):_vm._e(),(item.status === 'Deployed' && !_vm.isShowEngSoft)?_c('div',{staticClass:"d-flex justify-content-center align-items-center",staticStyle:{"gap":"5px"}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.auto",value:("Return ticket"),expression:"`Return ticket`",modifiers:{"hover":true,"auto":true}}],staticClass:"clickable text-danger",attrs:{"icon":"ArrowLeftCircleIcon","size":"18"},on:{"click":function($event){return _vm.showReturnTicket(item.ticket_id)}}}),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.auto",value:("Complete ticket"),expression:"`Complete ticket`",modifiers:{"hover":true,"auto":true}}],staticClass:"clickable text-success",attrs:{"icon":"CheckCircleIcon","size":"18"},on:{"click":function($event){return _vm.completeTicket(item.ticket_id)}}})],1):_vm._e(),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.auto",value:("View ticket"),expression:"`View ticket`",modifiers:{"hover":true,"auto":true}}],staticClass:"clickable",class:(_vm.isReviewer &&
                  item.pending == 1 &&
                  item.status == 'In Filter') ||
                (_vm.isShowEngSoft &&
                  item.pending == 1 &&
                  item.owner_id == _vm.currentUser.user_id)
                  ? 'text-warning'
                  : 'text-primary',attrs:{"icon":"EyeIcon","size":"18"},on:{"click":function($event){return _vm.viewTicket(item)}}})],1)]}}])})]},proxy:true}])}),(_vm.showDescription)?_c('modal-detail-issue',{attrs:{"description":_vm.descriptionSelected,"showDescription":_vm.showDescription},on:{"close":_vm.closeDescription}}):_vm._e(),(_vm.showReturnModal)?_c('ModalReturned',{attrs:{"ticket_id":_vm.ticket_id,"showModal":_vm.showReturnModal},on:{"close":function($event){_vm.showReturnModal = false},"refresh":_vm.refresh}}):_vm._e(),(_vm.modalDetails)?_c('ModalDetails',{ref:"modalDetails",attrs:{"ticket_id":_vm.ticket_id},on:{"refreshKanban":function($event){return _vm.refresh()},"close":function($event){return _vm.closeModalDetails()}}}):_vm._e(),(_vm.showTimeline)?_c('Timeline',{attrs:{"title":"Timeline Status","subtitle":("Ticket: " + (_vm.descriptionSelected.ticketId)),"timeLine":_vm.timeLine},on:{"close":function($event){_vm.showTimeline = false}}}):_vm._e(),(_vm.showRejected)?_c('ModalRejected',{attrs:{"ticket_id":_vm.ticket_id,"isReviewer":_vm.isReviewer},on:{"close":function($event){_vm.showRejected = false},"rejected":function($event){_vm.showRejected = false;
      _vm.refresh();}}}):_vm._e(),(_vm.showErrorModal)?_c('ModalErrorType',{ref:"modalErrorType",attrs:{"ticketId":_vm.ticket_id},on:{"close":function($event){_vm.showErrorModal = false},"refresh":function($event){return _vm.refresh()}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }