<template>
  <div>
    <div>
      <b-form inline @submit.prevent="">
        <b-input-group
          class="mb-2 mr-sm-2 mb-sm-0"
          v-if="[1, 41].includes(currentUser.user_id) || isChief"
        >
          <template #prepend>
            <div class="input-group-text">
              <b-icon icon="person-circle" class="h4 m-0"> </b-icon>
            </div>
          </template>
          <b-form-select
            v-model="owner"
            :options="owners"
            @input="getKanbanData()"
          >
          </b-form-select>
        </b-input-group>
        <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
          <template #prepend>
            <div class="input-group-text">
              <b-icon icon="person-circle" class="h4 m-0"> </b-icon>
            </div>
          </template>
          <b-form-select
            v-model="module"
            :options="modules"
            @input="getKanbanData()"
          >
          </b-form-select>
        </b-input-group>
        <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
          <template #prepend>
            <div class="input-group-text">
              <feather-icon icon="HashIcon" />
            </div>
          </template>
          <b-form-input
            type="text"
            placeholder="TICKET ID"
            v-model="ticketIdSearch"
            @keypress.enter="getKanbanData()"
          ></b-form-input>
        </b-input-group>
        <b-button variant="primary" class="mr-1" @click="getKanbanData()">
          <feather-icon icon="SearchIcon" /> Search</b-button
        >
        <b-button variant="warning" @click="refreshKanban()">
          <feather-icon icon="RefreshCcwIcon" />
        </b-button>
      </b-form>
    </div>
    <b-row class="mt-2">
      <b-col v-for="(kanban, kanbanIndex) in dataKanban" :key="kanbanIndex">
        <div>
          <div
            class="title-status mb-2 text-uppercase card"
            :style="{ borderColor: kanban.color }"
          >
            {{ kanban.name }}
          </div>
          <draggable
            class="list-group kanban-column"
            :list="kanban.statuses"
            group="tasks"
            ghost-class="ghost"
            @change="finish($event, kanban.id)"
          >
            <div
              v-for="(statuses, index) in kanban.statuses"
              :key="index"
              class="bg-transparent"
            >
              <b-card
                v-if="statuses.hide == hiddenTicket"
                class="cursor-pointer"
                :class="classForTicket(statuses)"
                @contextmenu.prevent="
                  kanban.id == 5
                    ? optionsKanban(kanban, kanbanIndex, index)
                    : ''
                "
                @click="
                  showDetail(statuses, statuses.ticket_id, kanbanIndex, index)
                "
              >
                <div class="flex-space-between">
                  <b>{{ statuses.title }}</b>
                  <img
                    :src="generateImage(statuses.owner)"
                    alt="Avatar"
                    class="avatar-dev"
                  />
                </div>

                <div class="flex-space-between mt-1">
                  <small>{{ statuses.user }}</small>
                  <small>{{ statuses.module }}</small>
                </div>

                <div class="flex-space-between mt-1">
                  <small>#{{ statuses.ticket_id }}</small>
                  <feather-icon
                    icon="MessageSquareIcon"
                    class="text-danger"
                    v-if="
                      statuses.sender_message_id != currentUser.user_id &&
                      statuses.status_view_messages == 0
                    "
                  />
                </div>
              </b-card>
              <b-dropdown
                :ref="`dropdown-${kanbanIndex}-${index}`"
                class="absolute"
                style="width: 0px; height: 0px; margin-top: -45px !important"
                variant="transparent"
                toggle-class="text-decoration-none"
                v-if="kanban.id == 5"
                no-caret
              >
                <template #button-content />
                <b-dropdown-item
                  v-b-toggle.sidebar-right
                  @click="updatesHideTicket(statuses)"
                >
                  <b-icon
                    :icon="statuses.hide ? 'eye' : 'eye-slash'"
                    class="mr-50"
                  />
                  <span v-text="statuses.hide ? 'Show' : 'Hidden'"></span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </draggable>
        </div>
      </b-col>
    </b-row>

    <modal-details
      ref="modalDetails"
      @modalInformation="modalMoreInformation"
      @refreshKanban="initKanban"
      :ticket_id="ticket_id"
      v-if="modalDetails"
      @close="closeModalDetails()"
      @updateDuedate="updateDuedate"
    ></modal-details>
  </div>
</template>
<script>
import draggable from "vuedraggable"
import ModalDetails from "./modals/ModalDetailsKanban.vue"
import moment from "moment"
import helpdeskService from "@/views/commons/components/helpdesk/services/helpdesk.service"
import { mapGetters } from "vuex"
import navMenuItems from "@/navigation/vertical"
export default {
  components: {
    draggable,
    ModalDetails,
  },
  data() {
    return {
      ticket_id: null,
      ticketIdSearch: null,
      hiddenTicket: 0,
      dataKanban: [],
      requestInformation: "",
      showMoreInformation: false,
      ticketTemp: null,
      modalDetails: false,
      item: {},
      owner: null,
      owners: [],
      module: null,
      modules: [],
      showPopover: false,
      details: {
        kanbanIndex: null,
        index: null,
      },
    }
  },
  created() {
    this.initKanban()
  },
  methods: {
    async loadModules() {
      this.modules = []
      const response = await helpdeskService.getModules()
      this.modules.push({
        value: null,
        text: "All Modules",
      })
      response.data.forEach((item) => {
        this.modules.push({
          value: item.id,
          text: item.name,
        })
      })
    },
    async getOwners() {
      this.owners = []
      const data = await helpdeskService.getOwners()
      this.owners.push({
        value: null,
        text: "All Owners",
      })
      data.forEach((item) => {
        this.owners.push({
          value: item.id,
          text: item.name,
        })
      })
    },
    formatDateTime(date) {
      if (date == null) return "Waiting...."
      return moment(date).format("MM/DD/YYYY HH:mm")
    },
    generateImage(name) {
      if (name) {
        return `https://ui-avatars.com/api/?name=${name}&background=68aa13&bold=true`
      } else {
        return `https://ui-avatars.com/api/?name=?`
      }
    },
    optionsKanban(item, kanbanIndex, index) {
      this.item = item
      this.$refs[`dropdown-${kanbanIndex}-${index}`][0].show()
    },
    refreshKanban() {
      this.owner = null
      this.module = null
      this.hiddenTicket = 0
      this.ticketIdSearch = null
      this.initKanban()
    },

    async refreshCountNavbar() {
      const params = {
        modul_id: 12,
        user_id: this.currentUser.user_id,
      }

      const response = await helpdeskService.getCounterNotifications(params)
      if (Object.keys(response.data).length > 0) {
        navMenuItems.forEach((item) => {
          if (item?.route === "helpdesk-ti") {
            item.tag = response.data.total
          }
        })
      }
    },

    async initKanban() {
      this.addPreloader()
      this.owners = []
      this.modules = []
      await this.getKanbanData()
      this.refreshCountNavbar()
      this.loadModules()
      this.getOwners()
      this.removePreloader()
    },

    async getKanbanData() {
      this.dataKanban = []
      const params = {
        module_id: this.module,
        owner_id: this.currentUser.user_id,
        hide: this.hiddenTicket,
        ticket_id: this.ticketIdSearch,
      }
      const response = await helpdeskService.getDataKanban(params)
      this.dataKanban = response.data.data
    },

    async finish({ added }, newIndex) {
      if (added) {
        if (added.element.status_id == 4) {
          this.getKanbanData()
          return
        }
        const confirm = await this.showConfirmSwal(
          "Are you sure?",
          "Would you like to request more information?"
        )
        if (!confirm.isConfirmed) {
          this.getKanbanData()
          return
        }
        this.updateStatusTicket(added, newIndex)
      }
    },

    async updateStatusTicket(added, newIndex) {
      this.addPreloader()
      const params = {
        userId: this.currentUser.user_id,
        ticketId: added.element.ticket_id,
        statusId: newIndex,
      }
      await helpdeskService.updateStatusTicket(params)
      this.initKanban()
      this.removePreloader()
    },

    showDetail(item, id, kanbanIndex, index) {
      item.pending = 0
      this.ticket_id = id
      this.details.kanbanIndex = kanbanIndex
      this.details.index = index
      this.modalDetails = true
    },
    classForTicket(item) {
      let classTicket = ""
      const now = moment()
      const dateIn48Hours = moment(item.created_at).add(48, "hours")
      if (now.isAfter(dateIn48Hours)) {
        classTicket += " border-danger-ticket"
      }
      return classTicket
    },

    updateDuedate(datetime) {
      this.dataKanban[this.details.kanbanIndex].statuses[
        this.details.index
      ].due_date = datetime
    },

    closeModalDetails() {
      this.modalDetails = false
    },

    async updatesHideTicket(item) {
      this.addPreloader()
      try {
        const params = {
          ticket_id: item.ticket_id,
          hide: !item.hide,
          user_id: this.currentUser.user_id,
        }
        await helpdeskService.updateStatusHide(params)
        item.hide = !item.hide
        this.removePreloader()
      } catch (e) {
        this.$swal.fire({
          type: "error",
          title: e,
        })
        this.removePreloader()
      }
    },

    refreshAllKanban() {
      this.initKanban()
      this.$refs.modalDetails?.getComments()
      this.$refs.modalDetails?.refreshStatus(2)
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
}
</script>
<style>
.border-danger-ticket {
  border: 2px solid #fff;
  border-radius: 10px;
  animation: mymove 1s infinite;
}

@keyframes mymove {
  0% {
    border-color: #fff;
  }
  50% {
    border-color: #db2100;
  }
  100% {
    border-color: #fff;
  }
}
.kanban-column {
  min-height: 100vh;
}

.title-status {
  font-weight: bold;
  font-size: 1.2em;
  background-color: white;
  border-top: blue 3px solid;
  padding: 5px;
}

.flex-title {
  display: flex;
  justify-content: space-between;
}

.flex-end {
  display: flex;
  justify-content: end;
}

.avatar-dev {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
}

.title-issue {
  font-weight: bold;
  font-size: 1.2em;
  color: #4a5568;
}

.text-date {
  font-size: 1em;
  color: #586376;
}

.circle-color {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #4a5568;
}

.flex-badge {
  display: flex;
  justify-content: space-between;
  align-content: center;
  background-color: #bac4c961;
  padding: 1px 5px;
  align-items: center;
}

.kanban-table {
  background: #eeeeee;
  height: 100vh;
  overflow-y: auto;
}

.ghost {
  opacity: 0.5;
  background: #cacaca;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.font-size-datetime {
  font-size: 0.8em;
}

.scroll-kanban {
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.scroll-kanban::-webkit-scrollbar {
  width: 7px;
}

.scroll-kanban::-webkit-scrollbar-thumb:hover {
  border-radius: 10px;
}

.absolute {
  position: absolute;
}

.flashes {
  animation-name: parpadeo;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name: parpadeo;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}

@-moz-keyframes parpadeo {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes parpadeo {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes parpadeo {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>