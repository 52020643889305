<template>
  <b-modal
    v-model="showModal"
    modal-class="modal-primary"
    title-class="h3 text-white"
    title="WHY DO YOU WANT TO RETURN THE TICKET?"
    size="lg"
    @hidden="closeModal()"
    hide-footer
    centered
    no-close-on-backdrop
  >
    <template>
      <ValidationObserver ref="validForm">
        <ValidationProvider rules="required" v-slot="{ errors }">
          <b-form-group
            id="input-group-answer"
            label-for="input-answer"
            description="Please enter the reason for returning the ticket"
          >
            <b-textarea
              id="input-description"
              v-model="requestInformation"
              type="text"
              required
              class="bg-color-white font-bureau-style border-hover-p"
              rows="5"
              :class="{ 'is-invalid': errors[0] }"
            ></b-textarea>
          </b-form-group>
        </ValidationProvider>
      </ValidationObserver>
      <div class="text-right">
        <b-button variant="secondary" @click="closeRequest(false)"
          >Cancel
        </b-button>
        <b-button variant="danger" class="ml-2" @click="closeRequest(true)">
          Return
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex"
import helpdeskService from "@/views/commons/components/helpdesk/services/helpdesk.service"
export default {
  props: {
    ticket_id: {
      type: String,
      default: null,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      requestInformation: null,
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  created() {},
  methods: {
    closeModal() {
      this.$emit("close")
    },
    async closeRequest(send = false) {
      if (send) {
        if (["", null].includes(this.requestInformation.trim())) return
        this.addPreloader()
        try {
          const params = {
            ticketId: this.ticket_id,
            statusId: 2,
            userId: this.currentUser.user_id,
            description: this.requestInformation,
            return: true,
          }
          await helpdeskService.updateStatusTicket(params)
          this.removePreloader()
          this.closeModal()
          this.requestInformation = null
          this.$emit("refresh")
        } catch (e) {
          console.log(e)
          this.removePreloader()
        }
      } else {
        this.$emit("refresh")
        this.closeModal()
      }
    },
  },
}
</script>