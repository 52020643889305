var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"myModal",attrs:{"title":"SET ERROR TYPE","modal-class":"modal-primary","title-class":"h3 text-white font-weight-bolder","size":"modal-timeline","hide-footer":"","centered":""},on:{"hidden":function($event){return _vm.hideModal()}}},[_c('b-row',{staticClass:"m-0 py-1 px-1 my-1 content-att",class:_vm.isDarkSkin ? 'bg-rgba' : 'bg-rgba-light'},[_c('span',{staticClass:"attr-text col-md-4"},[_vm._v("ERROR TYPE ")]),(_vm.currentUser.is_reviewer == 0)?_c('div',{staticClass:"col-md-8"},[_c('b-dropdown',{staticClass:"wo-padding",attrs:{"no-caret":"","variant":"none","disabled":_vm.currentUser.user_id == 360 ||
          (Boolean(_vm.details.on_review) &&
            (_vm.details.status_id == 5 || _vm.details.deleted_by != null))},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticClass:"attr-content",style:(_vm.isDarkSkin
                ? ("\n                  background-color: rgba(" + (_vm.hexToRgb(
                    _vm.errorColor || '#FAFAFA'
                  )) + ", 0.2);\n                  color: " + (_vm.errorColor || '#FAFAFA') + ";\n                ")
                : ("\n                  background-color: none;\n                  color: " + (_vm.errorColor
                      ? _vm.errorName == 'Rule Conflict'
                        ? '#ff9f43'
                        : _vm.errorColor
                      : '#878484') + ";\n                  border: 1px solid " + (_vm.errorColor
                      ? _vm.errorName == 'Rule Conflict'
                        ? '#ff9f43'
                        : _vm.errorColor
                      : '#878484') + "\n                "))},[_c('b-icon',{attrs:{"icon":"circle-fill"}}),_vm._v(" "+_vm._s(_vm.errorName)+" ")],1)]},proxy:true}],null,false,104961694)},_vm._l((_vm.errTypes),function(item,index){return _c('b-dropdown-item',{key:index,attrs:{"disabled":(_vm.currentUser.eng_soft == 0 &&
              _vm.currentUser.is_reviewer == 1 &&
              _vm.details.status_id != 1) ||
            item.id == _vm.details.error_id},on:{"click":function($event){return _vm.changeErrorType(item.id)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1):_c('div',{staticClass:"col-md-8"},[_c('b-dropdown',{staticClass:"wo-padding",attrs:{"no-caret":"","variant":"none","disabled":_vm.currentUser.user_id == 360 ||
          (Boolean(_vm.details.on_review) &&
            (_vm.details.status_id == 5 || _vm.details.deleted_by != null))},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticClass:"attr-content",style:(_vm.isDarkSkin
                ? ("\n                  background-color: rgba(" + (_vm.hexToRgb(
                    _vm.errorRevColor || '#FAFAFA'
                  )) + ", 0.2);\n                  color: " + (_vm.errorRevColor || '#FAFAFA') + ";\n                ")
                : ("\n                  background-color: none;\n                  color: " + (_vm.errorRevColor
                      ? _vm.errorRevName == 'Rule Conflict'
                        ? '#ff9f43'
                        : _vm.errorRevColor
                      : '#878484') + ";\n                  border: 1px solid " + (_vm.errorRevColor
                      ? _vm.errorRevName == 'Rule Conflict'
                        ? '#ff9f43'
                        : _vm.errorRevColor
                      : '#878484') + "\n                "))},[_c('b-icon',{attrs:{"icon":"circle-fill"}}),_vm._v(" "+_vm._s(_vm.errorRevName)+" ")],1)]},proxy:true}])},_vm._l((_vm.errTypes),function(item,index){return _c('b-dropdown-item',{key:index,attrs:{"disabled":(_vm.currentUser.eng_soft == 0 &&
              _vm.currentUser.is_reviewer == 1 &&
              _vm.details.status_id != 1 &&
              !_vm.noErrorRev) ||
            item.id == _vm.details.error_id_reviewer},on:{"click":function($event){return _vm.changeErrorType(item.id)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)]),(_vm.errorName == 'Bug' && _vm.currentUser.is_reviewer == 0)?_c('b-row',{staticClass:"m-0 py-1 px-1 my-1 content-att",class:_vm.isDarkSkin ? 'bg-rgba' : 'bg-rgba-light'},[_c('span',{staticClass:"attr-text col-md-4"},[_vm._v("TEAM RESPONSIBLE")]),_c('div',{staticClass:"col-md-8"},[_c('b-form-select',{attrs:{"options":_vm.teams,"disabled":_vm.currentUser.eng_soft == 0 ||
          _vm.currentUser.user_id == 360 ||
          (Boolean(_vm.details.on_review) &&
            (_vm.details.status_id == 5 || _vm.details.deleted_by != null)),"value-field":"id","text-field":"name"},on:{"change":function($event){_vm.details.error_developer_responsible = null}},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Please select a Team")])]},proxy:true}],null,false,447477218),model:{value:(_vm.details.error_team_id),callback:function ($$v) {_vm.$set(_vm.details, "error_team_id", $$v)},expression:"details.error_team_id"}})],1)]):_vm._e(),(_vm.errorName == 'Bug' && _vm.currentUser.is_reviewer == 0)?_c('b-row',{staticClass:"m-0 py-1 px-1 my-1 content-att",class:_vm.isDarkSkin ? 'bg-rgba' : 'bg-rgba-light'},[_c('span',{staticClass:"attr-text col-md-4"},[_vm._v("DEVELOPER RESPONSIBLE")]),_c('div',{staticClass:"col-md-8"},[_c('b-form-select',{attrs:{"options":_vm.developersList,"disabled":_vm.currentUser.eng_soft == 0 ||
          _vm.currentUser.user_id == 360 ||
          (Boolean(_vm.details.on_review) &&
            (_vm.details.status_id == 5 || _vm.details.deleted_by != null)),"value-field":"id","text-field":"name"},on:{"change":function($event){return _vm.changeTeam($event)}},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Please select a Developer ")])]},proxy:true}],null,false,350539145),model:{value:(_vm.details.error_developer_responsible),callback:function ($$v) {_vm.$set(_vm.details, "error_developer_responsible", $$v)},expression:"details.error_developer_responsible"}})],1)]):_vm._e(),_c('br'),(_vm.currentUser.is_reviewer == 0)?_c('timeline-component',{attrs:{"title":"TIMELINE ERROR TYPE","subtitle":("TICKET: " + (_vm.details.ticket_id)),"timeLine":_vm.timeline,"typeData":"error"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }