<template>
  <div>
    <b-modal
      modal-class="modal-top-0"
      centered
      ref="showDescription"
      title-class="h3 text-white"
      @hidden="$emit('close')"
      :title="`Ticket #${description.ticketId} (${description.status})`"
      hide-footer
    >
      <div class="font-weight-bolder">TICKET DESCRIPTION</div>
      <Conversation :selectedType="2" :ticket_id="description.ticketId" />
    </b-modal>
  </div>
</template>

<script>
import Conversation from "@/views/commons/components/helpdesk/components/partials/Conversation.vue"
export default {
  props: {
    description: {
      type: Object,
      default: {},
    },
  },
  components: {
    Conversation,
  },
  mounted() {
    this.toggleModal("showDescription")
  },
}
</script>