export default [
  {
    key: "ticket",
    label: "TICKET",
    sortable: false,
    thStyle: { width: "20%" },
    tdStyle: { width: "20%" },
    visible: true,
  },
  {
    key: "binnacle",
    label: "BINNACLE",
    sortable: false,
    visible: true,
    tdClass: "text-center",
    thClass: "text-center",
  },
  {
    key: "module",
    label: "MODULE",
    sortable: false,
    visible: true,
    tdClass: "text-center",
    thClass: "text-center",
  },
  {
    key: "section",
    label: "SECTION",
    sortable: false,
    visible: true,
    tdClass: "text-center",
    thClass: "text-center",
  },
  {
    key: "category_ticket",
    label: "CATEGORY",
    sortable: false,
    visible: true,
    tdClass: "text-center",
    thClass: "text-center",
  },
  {
    key: "user",
    label: "REQUESTED BY",
    sortable: false,
    visible: true,
    tdClass: "text-center",
    thClass: "text-center",
  },
  {
    key: "status",
    label: "STATUS",
    sortable: false,
    visible: true,
    tdClass: "text-center",
    thClass: "text-center",
  },
  {
    key: "owner",
    label: "OWNER",
    sortable: false,
    visible: true,
    module: 12,
    tdClass: "text-center",
    thClass: "text-center",
  },
  {
    key: "due_date",
    label: "DUE DATE",
    sortable: false,
    visible: true,
    tdClass: "text-center",
    thClass: "text-center",
  },
  {
    key: "deleted_by",
    label: "REJECTED BY",
    sortable: false,
    visible: false,
    tdClass: "text-center",
    thClass: "text-center",
  },
  {
    key: "motive",
    label: "MOTIVE",
    sortable: false,
    visible: false,
    tdClass: "text-center",
    thClass: "text-center",
  },
  {
    key: "error_review",
    label: "ERROR FILTER",
    sortable: false,
    visible: true,
    tdClass: "text-center",
    thClass: "text-center",
  },
  {
    key: "error_name",
    label: "ERROR PG",
    sortable: false,
    visible: true,
    tdClass: "text-center",
    thClass: "text-center",
  },
  {
    key: "actions",
    label: "ACTIONS",
    sortable: false,
    visible: true,
    tdClass: "text-center",
    thClass: "text-center",
  },
];
