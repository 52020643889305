var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"showNewIssue",attrs:{"modal-class":"modal-top-0","size":"lg","title-class":"h3 text-white font-weight-bolder","body-class":"body-modal-issue","centered":"","title":"Create a new ticket","no-close-on-backdrop":"","no-close-on-esc":""},on:{"hidden":function($event){return _vm.closeModal()}},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticStyle:{"display":"flex","justify-content":"end","align-items":"center"}},[_c('b-button',{staticClass:"rounded mr-2 btn-ui-orange",on:{"click":function($event){return _vm.closeModal()}}},[_vm._v("Cancel")]),_c('b-button',{staticClass:"rounded btn-ui-green",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.sendIssue()}}},[_vm._v("Send")])],1)]},proxy:true}])},[_c('b-form',{ref:"form"},[_c('ValidationObserver',{ref:"validForm"},[_c('b-row',{staticClass:"my-0"},[_c('ValidationProvider',{staticClass:"col-6",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"id":"input-group-module","label":"Module","label-for":"input-module","description":"Module of the ticket"}},[_c('b-form-select',{staticClass:"select-icon-none bg-color-white font-bureau-style border-hover-p",class:{ 'is-invalid': errors[0] },attrs:{"options":_vm.modulesOptions,"disabled":"","title":"Modules"},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Please select a Module ")])]},proxy:true}],null,true),model:{value:(_vm.moduleId),callback:function ($$v) {_vm.moduleId=$$v},expression:"moduleId"}})],1)]}}])}),_c('ValidationProvider',{staticClass:"col-6",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"id":"input-group-section","label":"Category","label-for":"input-section","description":"Category of the ticket"}},[_c('b-form-select',{staticClass:"select-icon-none bg-color-white font-bureau-style border-hover-p",class:{ 'is-invalid': errors[0] },attrs:{"id":"input-priority","options":_vm.categories,"required":""},on:{"change":function($event){return _vm.getTitlesOfTickets()}},model:{value:(_vm.issue.category),callback:function ($$v) {_vm.$set(_vm.issue, "category", $$v)},expression:"issue.category"}})],1)]}}])})],1),(_vm.issue.category == 1)?_c('ValidationProvider',{attrs:{"rules":_vm.issue.category == 1 ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"id":"input-group-section","label":"Section","label-for":"input-section","description":"Section of the ticket"}},[_c('b-form-select',{staticClass:"select-icon-none bg-color-white font-bureau-style border-hover-p",class:{ 'is-invalid': errors[0] },attrs:{"id":"input-priority","options":_vm.sections,"disabled":_vm.issue.category == 2 || _vm.issue.category == null},on:{"change":function($event){return _vm.getTitlesOfTickets()}},model:{value:(_vm.issue.sectionName),callback:function ($$v) {_vm.$set(_vm.issue, "sectionName", $$v)},expression:"issue.sectionName"}})],1)]}}],null,false,1800437943)}):_vm._e(),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.issue.category == 2)?_c('b-form-group',{attrs:{"id":"input-group-section","label":"Title","label-for":"input-section"}},[_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.currentUser.eng_soft)?_c('b-button',{staticStyle:{"height":"2.714rem"},attrs:{"variant":"primary","disabled":_vm.validateTitleMessage != null},on:{"click":function($event){return _vm.openModalNewTitle()}}},[_vm._v("New "),_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"15"}})],1):_vm._e()]},proxy:true}],null,true)},[_c('b-form-select',{staticClass:"select-icon-none bg-color-white font-bureau-style border-hover-p",class:{ 'is-invalid': errors[0] },attrs:{"id":"input-priority","options":_vm.defaultDescription,"disabled":_vm.validateTitleMessage != null,"required":""},on:{"change":function($event){return _vm.changeDefaultDescription()}},model:{value:(_vm.issue.messageId),callback:function ($$v) {_vm.$set(_vm.issue, "messageId", $$v)},expression:"issue.messageId"}})],1),_c('small',{staticClass:"text-danger",domProps:{"textContent":_vm._s(_vm.validateTitleMessage)}})],1):_vm._e(),(_vm.issue.category == 1 || _vm.issue.messageId === 999)?_c('b-form-group',{attrs:{"id":"input-group-section","label":_vm.issue.category == 1 ? 'Title' : 'Specific title',"label-for":"input-section"}},[_c('b-input-group',[_c('b-input',{staticClass:"bg-color-white font-bureau-style border-hover-p",class:{ 'is-invalid': errors[0] },attrs:{"id":"input-title","type":"text","required":""},model:{value:(_vm.issue.title),callback:function ($$v) {_vm.$set(_vm.issue, "title", $$v)},expression:"issue.title"}})],1)],1):_vm._e()]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"id":"input-group-description","label":"Description","label-for":"input-description","description":"Long ticket description - Please be as descriptive as possible so our team can reproduce your ticket."}},[_c('b-textarea',{staticClass:"bg-color-white font-bureau-style border-hover-p",class:{ 'is-invalid': errors[0] },attrs:{"id":"input-description","type":"text","required":"","rows":"5"},model:{value:(_vm.issue.description),callback:function ($$v) {_vm.$set(_vm.issue, "description", $$v)},expression:"issue.description"}})],1)]}}])})],1),_c('drag-and-drop',{attrs:{"filesArray":_vm.files},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}})],1),(_vm.showNewTitle)?_c('NewTitleTicket',{attrs:{"categoryIssue":_vm.issue.category,"sectionIsuue":_vm.issue.sectionName},on:{"close":function($event){_vm.showNewTitle = false},"inserted":function($event){_vm.showNewTitle = false;
      _vm.getTitlesOfTickets();}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }