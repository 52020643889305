<!-- ModalComponent.vue -->
<template>
  <b-modal
    ref="myModal"
    title="SET ERROR TYPE"
    modal-class="modal-primary"
    title-class="h3 text-white font-weight-bolder"
    size="modal-timeline"
    hide-footer
    centered
    @hidden="hideModal()"
  >
    <b-row
      class="m-0 py-1 px-1 my-1 content-att"
      :class="isDarkSkin ? 'bg-rgba' : 'bg-rgba-light'"
    >
      <span class="attr-text col-md-4">ERROR TYPE </span>
      <div class="col-md-8" v-if="currentUser.is_reviewer == 0">
        <b-dropdown
          no-caret
          variant="none"
          class="wo-padding"
          :disabled="
            currentUser.user_id == 360 ||
            (Boolean(details.on_review) &&
              (details.status_id == 5 || details.deleted_by != null))
          "
        >
          <template #button-content>
            <div
              class="attr-content"
              :style="
                isDarkSkin
                  ? `
                    background-color: rgba(${hexToRgb(
                      errorColor || '#FAFAFA'
                    )}, 0.2);
                    color: ${errorColor || '#FAFAFA'};
                  `
                  : `
                    background-color: none;
                    color: ${
                      errorColor
                        ? errorName == 'Rule Conflict'
                          ? '#ff9f43'
                          : errorColor
                        : '#878484'
                    };
                    border: 1px solid ${
                      errorColor
                        ? errorName == 'Rule Conflict'
                          ? '#ff9f43'
                          : errorColor
                        : '#878484'
                    }
                  `
              "
            >
              <b-icon icon="circle-fill"> </b-icon>
              <!-- TODO Cambiar por item.errorName -->
              {{ errorName }}
            </div>
          </template>
          <b-dropdown-item
            v-for="(item, index) in errTypes"
            :disabled="
              (currentUser.eng_soft == 0 &&
                currentUser.is_reviewer == 1 &&
                details.status_id != 1) ||
              item.id == details.error_id
            "
            :key="index"
            @click="changeErrorType(item.id)"
          >
            {{ item.name }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="col-md-8" v-else>
        <b-dropdown
          no-caret
          variant="none"
          class="wo-padding"
          :disabled="
            currentUser.user_id == 360 ||
            (Boolean(details.on_review) &&
              (details.status_id == 5 || details.deleted_by != null))
          "
        >
          <template #button-content>
            <div
              class="attr-content"
              :style="
                isDarkSkin
                  ? `
                    background-color: rgba(${hexToRgb(
                      errorRevColor || '#FAFAFA'
                    )}, 0.2);
                    color: ${errorRevColor || '#FAFAFA'};
                  `
                  : `
                    background-color: none;
                    color: ${
                      errorRevColor
                        ? errorRevName == 'Rule Conflict'
                          ? '#ff9f43'
                          : errorRevColor
                        : '#878484'
                    };
                    border: 1px solid ${
                      errorRevColor
                        ? errorRevName == 'Rule Conflict'
                          ? '#ff9f43'
                          : errorRevColor
                        : '#878484'
                    }
                  `
              "
            >
              <b-icon icon="circle-fill"> </b-icon>
              <!-- TODO Cambiar por item.errorRevName -->
              {{ errorRevName }}
            </div>
          </template>
          <b-dropdown-item
            v-for="(item, index) in errTypes"
            :disabled="
              (currentUser.eng_soft == 0 &&
                currentUser.is_reviewer == 1 &&
                details.status_id != 1 &&
                !noErrorRev) ||
              item.id == details.error_id_reviewer
            "
            :key="index"
            @click="changeErrorType(item.id)"
          >
            {{ item.name }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </b-row>
    <b-row
      class="m-0 py-1 px-1 my-1 content-att"
      :class="isDarkSkin ? 'bg-rgba' : 'bg-rgba-light'"
      v-if="errorName == 'Bug' && currentUser.is_reviewer == 0"
    >
      <span class="attr-text col-md-4">TEAM RESPONSIBLE</span>
      <div class="col-md-8">
        <b-form-select
          v-model="details.error_team_id"
          :options="teams"
          :disabled="
            currentUser.eng_soft == 0 ||
            currentUser.user_id == 360 ||
            (Boolean(details.on_review) &&
              (details.status_id == 5 || details.deleted_by != null))
          "
          value-field="id"
          text-field="name"
          @change="details.error_developer_responsible = null"
        >
          <template #first>
            <b-form-select-option :value="null" disabled
              >Please select a Team</b-form-select-option
            >
          </template>
        </b-form-select>
      </div>
    </b-row>
    <b-row
      class="m-0 py-1 px-1 my-1 content-att"
      :class="isDarkSkin ? 'bg-rgba' : 'bg-rgba-light'"
      v-if="errorName == 'Bug' && currentUser.is_reviewer == 0"
    >
      <span class="attr-text col-md-4">DEVELOPER RESPONSIBLE</span>
      <div class="col-md-8">
        <b-form-select
          v-model="details.error_developer_responsible"
          :options="developersList"
          :disabled="
            currentUser.eng_soft == 0 ||
            currentUser.user_id == 360 ||
            (Boolean(details.on_review) &&
              (details.status_id == 5 || details.deleted_by != null))
          "
          value-field="id"
          text-field="name"
          @change="changeTeam($event)"
        >
          <template #first>
            <b-form-select-option :value="null" disabled
              >Please select a Developer
            </b-form-select-option>
          </template>
        </b-form-select>
      </div>
    </b-row>
    <br />
    <timeline-component
      v-if="currentUser.is_reviewer == 0"
      title="TIMELINE ERROR TYPE"
      :subtitle="`TICKET: ${details.ticket_id}`"
      :timeLine="timeline"
      typeData="error"
    />
  </b-modal>
</template>
  
  <script>
import store from "@/store";
import helpdeskService from "@/views/commons/components/helpdesk/services/helpdesk.service";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import TimelineComponent from "@/views/commons/utilities/TimelineComponent.vue";

export default {
  components: {
    TimelineComponent,
  },
  name: "ModalErrorType",
  props: ["ticketId"],
  emmits: ["close"],
  data() {
    return {
      details: {
        ticket_id: "",
      },
      statusId: null,
      isReviewed: 0,
      errTypes: [],
      teams: [],
      teamId: null,
      timeline: [],
      developersListRaw: [],
      errorName: "Unknown",
      errorColor: null,
      errorRevName: "Unknown",
      errorRevColor: null,
      noErrorRev: false,
    };
  },
  methods: {
    show() {
      this.$refs.myModal.show();
    },
    hideModal() {
      this.$emit("close");
    },
    async getTicket() {
      try {
        if (this.ticketId) {
          this.addPreloader();
          const params = {
            ticket_id: this.ticketId,
            user_id: this.currentUser.user_id,
            is_reviewer: this.currentUser.is_reviewer,
          };

          const { data } = await helpdeskService.getDetailsTicket(params);
          this.details = data.data;
          this.timeline = [
            {
              title: "Unknown",
              date: data.data.created_at,
            },
            ...data.timelist,
          ];
          this.isReviewed = data.is_reviewed;
          this.errTypes = data.error_types;
          this.statusId = data.data.status_id;
          this.teamId = this.details.error_team_id;
          this.teams = [...data.teams, { id: 0, name: "Old Team" }];
          this.developersListRaw = data.pg_developers;
          if (this.details.error_id) {
            const { color: colorError, name: nameError } =
              data.error_types.find((item) => item.id == this.details.error_id);
            this.errorName = nameError;
            this.errorColor = colorError;
          }
          if (this.details.error_id_reviewer) {
            const { color: colorError, name: nameError } =
              data.error_types.find(
                (item) => item.id == this.details.error_id_reviewer
              );
            this.errorRevName = nameError;
            this.errorRevColor = colorError;
          } else {
            this.noErrorRev = true;
          }
          this.removePreloader();
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async changeErrorType(errorId) {
      try {
        const confirm = await this.showConfirmSwal(
          "Are you sure to change error type?",
          "You won't be able to revert this!"
        );
        if (!confirm.value) {
          return;
        }
        this.addPreloader();
        const params = {
          ticketId: this.details.ticket_id,
          errorId: errorId,
          statusId: this.details.status_id,
          userId: this.currentUser.user_id,
          isReviewer: this.currentUser.is_reviewer,
        };
        await helpdeskService.updateErrorTicket(params);
        if (errorId != 1) {
          this.details.error_team_id = null;
          await this.changeTeam(null);
        }
        this.refresh();
      } catch (e) {
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
      }
    },
    async changeTeam(devId) {
      try {
        this.addPreloader();
        const params = {
          ticketId: this.details.ticket_id,
          teamId: this.details.error_team_id,
          developerId: devId,
          statusId: this.details.status_id,
          userId: this.currentUser.user_id,
        };
        await helpdeskService.updateTeamDeveloperTicket(params);
        this.refresh();
      } catch (e) {
        alert(e);
      } finally {
        this.removePreloader();
        if (devId) {
          this.$toast({
            component: ToastificationContentVue,
            position: "top-right",
            props: {
              title: `Developer Updated`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `Developer updated successfully`,
            },
          });
        }
      }
    },
    refresh() {
      this.$emit("refresh", null);
      this.getTicket();
    },
  },
  computed: {
    currentUser() {
      return store.getters["auth/currentUser"];
    },
    colorError() {
      return `color: ${
        this.errorColor
      } !important; background: rgba(${this.hexToRgb(
        this.errorColor
      )}, 0.2)!important; font-size: 12px;`;
    },
    developersList() {
      const developers = this.developersListRaw
        .filter((dev) => dev.pg_team_id === this.details.error_team_id)
        .map((dev) => {
          return {
            id: dev.id,
            name: dev.first_name + " " + dev.last_name,
          };
        });
      return developers.length !== 0
        ? developers
        : [{ id: 0, name: "Developer" }];
    },
  },
  mounted() {
    this.$refs.myModal.show();
    this.getTicket();
  },
};
</script>
  
<style lang="scss" scoped>
.close-btn {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: #4d4d4d;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-btn-light {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: #cccccc;
  display: flex;
  justify-content: center;
  align-items: center;
}

.max-height-content {
  max-height: 60vh !important;
}
.title-header {
  font-size: 1rem;
  font-weight: 400;
  color: #7a8189;
}
.title-ticket {
  font-size: 1.8rem;
  font-weight: 500;
}
.show-module {
  box-shadow: 0 0 1.2px 1px rgb(0 0 0 / 0.1);
}
.bg-orange-i {
  color: #ff6045;
  font-size: 1.5rem;
}
.bg-orange {
  background: #ff6045 !important;
  border: 1px solid #ff6045 !important;
  color: white !important;
}
.module-name {
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
}
.attr-text {
  font-size: 1rem;
  font-weight: 500;
}
.attr-content {
  /* background-color: white; */
  box-shadow: 0 0 1.2px 1px rgb(0 0 0 / 0.1);
  border-radius: 5px;
  padding: 0.2rem 0.5rem;
  display: inline-block;
}
.content-att {
  place-items: center;
  border-radius: 5px;
}
.bg-rgba {
  background: rgb(0 0 0 / 18%) !important;
}
.bg-rgba-light {
  background: rgb(0 0 0 / 4%) !important;
}
.title-alert {
  font-size: 1.2rem;
}
.bg-close {
  color: #ececec;
  .dark {
    background-color: #585858;
  }
}
</style>